import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useRouterState } from '@abyss/ui/router/hooks/useRouterState';
import { PageLayout } from 'src/common/PageLayout/PageLayout';
import { AppContext } from 'src/app/App/AppProvider';
import { event } from '@abyss/web/tools/event';
import { styled } from '@abyss/web/tools/styled';
import { useLagoon } from '@abyss/web/hooks/useLagoon';
import { LeftNav } from '../../LeftNav/LeftNav';
import { OverviewCard } from './OverviewCard/OverviewCard';
import { RefineResult } from './navComponents/RefineResult';
import { SortBy } from './navComponents/SortBy';
import { Filters } from './navComponents/Filters';
import { TabletView } from './TabletView/TabletView';

const Wrapper = styled('div', {
  display: 'flex',
});

const Container = styled('div', {
  padding: '40px 0px 40px 20px',
  '@screen >= $xs': {
    paddingRight: '20px',
    width: '100%',
  },
  '@screen >= $xl': {
    paddingRight: '0px',
    width: '70%',
  },
});

const PageHeading = styled('h1', {
  color: 'rgb(0, 38, 119)',
  fontSize: '36px',
  fontWeight: '600',
  lineHeight: '40px',
  margin: '10px 0',
});

const PageContent = styled('div', {
  padding: '30px 0',
});

const SubTitle = styled('h5', {
  minHeight: '24px',
  color: 'rgb(51, 51, 51)',
  fontSize: '15px',
  fontWeight: 400,
  letterSpacing: '0px',
  lineHeight: '24px',

  '@screen >= $xs': {
    display: 'block',
  },

  '@screen >= $xl': {
    display: 'none',
  },
});

export const AllAPIs = ({ location }) => {
  const { apiList = [], filters, updateFilters } = React.useContext(AppContext);
  const router = useRouterState();
  const dsUserInfo = useSelector(state => state.GET_DS_USER_INFO.data);
  const userInfo = useSelector(state => state.GET_USER_INFO.data);
  const featureToggles = useLagoon('feature-toggles');
  const providerDemoToggle = featureToggles('PROVIDER_DEMOGRAPHIC_TOGGLE')
    ?.enabled;
  const priorAuthToggle = featureToggles('PRIOR_AUTH_TOGGLE')?.enabled;
  const trackitToggle = featureToggles('TRACKIT_TOGGLE')?.enabled;

  let apiListData = providerDemoToggle
    ? apiList
    : apiList.filter(api => api.key !== 'Provider Demographic API');

  apiListData = priorAuthToggle
    ? apiListData
    : apiListData.filter(api => api.key !== 'PriorAuth-API');

  apiListData = trackitToggle
    ? apiListData
    : apiListData.filter(api => api.key !== 'TrackIt');

  useEffect(() => {
    event('ALL_API_PAGE_LOAD', { dsUserInfo, userInfo });
    if (location.state) {
      const newFilters = filters.map(el => {
        const checked = el.title === location.state.filter;
        return {
          ...el,
          checked,
        };
      });
      updateFilters(newFilters);
    } else if (!router.previous.location?.pathname.includes('/all-apis')) {
      const newFilters = filters.map(el => {
        const checked = false;
        return {
          ...el,
          checked,
        };
      });
      updateFilters(newFilters);
    }
  }, []);

  return (
    <PageLayout>
      <Wrapper>
        <LeftNav>
          <RefineResult />
          <SortBy />
          <Filters />
        </LeftNav>
        <Container>
          <PageHeading>All APIs</PageHeading>
          <TabletView />
          <PageContent>
            <SubTitle>Showing {apiListData.length} Results</SubTitle>
            {apiListData.map((el, idx) => {
              const key = `${el.id}_${idx}`;
              return (
                <OverviewCard
                  key={key}
                  src={el.src}
                  label={el.label}
                  description={el.description}
                  route={el.route}
                />
              );
            })}
          </PageContent>
        </Container>
      </Wrapper>
    </PageLayout>
  );
};

AllAPIs.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.arrayOf(PropTypes.string),
  }),
};

AllAPIs.defaultProps = {
  location: {},
};
