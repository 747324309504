import React, { Fragment, useEffect, useState } from 'react';
import { NavMenu } from '@abyss/web/ui/NavMenu';
import { Layout } from '@abyss/web/ui/Layout';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { useLagoon } from '@abyss/web/hooks/useLagoon';
import { PageHeader } from '@abyss/web/ui/PageHeader';
import { styled } from '@abyss/web/tools/styled';
import { event } from '@abyss/web/tools/event';
import { useRouter } from '@abyss/ui/router/hooks/useRouter';
import { storage } from '@abyss/web/tools/storage';
import { config } from '@abyss/ui/tools/config';
import { useSelector } from 'react-redux';
import { Label } from '@abyss/web/ui/Label';
import { Badge } from '@abyss/web/ui/Badge';

import {
  checkInternalRole,
  getLeftNavData,
  leftNavMap,
} from 'src/common/Utils/utils';
import { saveAs } from 'file-saver';
import { SubscribeToAPIModal } from '../Content/SubscribeToAPIModal/SubscribeToAPIModal';
import { LogoutSuccessModal } from './LogoutSuccessModal/LogoutSuccessModal';
import { useUserInfo, resetUserInfo } from '../../../hooks/useUserInfo';
import { dropdownMenu } from './_mock';

const Icon = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '14px',
  width: '14px',
  borderRadius: '50%',
  border: 'solid 1px #002677',
  marginLeft: '4px',
  fontSize: '10px',
  fontWeight: '$bold',
});

const TechnicalDocHeader = styled('div', {
  color: '#002677',
  fontWeight: '700',
  fontSize: '20.25px',
  lineHeight: '24px',
});

const APISandboxSubheader = styled('div', {
  color: '#424242',
  fontSize: '15px',
  fontWeight: 400,
  marginTop: '8px',
  lineHeight: '20px',
});

const VisiblyHiddenDiv = styled('div', {
  '@screen >= $xl': {
    visibility: 'hidden',
    marginTop: '27px',
  },

  '@screen >= $lg': {
    visibility: 'hidden',
    marginTop: '27px',
  },
});

const StyledFlex = styled('div', {
  flexDirection: 'column',
});

const HeaderChildItemsDisplay = styled('div', {
  display: 'flex',
});

const TinManagementHeader = styled('div', {
  marginLeft: '15px',
  color: '#196ECF',
  fontWeight: 'bold',
});

const TinManagementDescription = styled('div', {
  color: '#424242',
  fontWeight: 'normal',
});

const CustomDiv = styled('div', {
  display: 'flex',
});

const DownloadDiv = styled('div', {
  paddingRight: '10px',
});
export const Header = () => {
  const router = useRouter();
  const [topMenuItems, setTopMenuItems] = useState([]);
  const [showLogoutSuccessModal, setshowLogoutSuccessModal] = useState(false);
  const [userInfoReset, runResetUserInfo] = resetUserInfo();
  const userInfo = useSelector(state => state.GET_USER_INFO.data);
  const dsUserInfo = useSelector(state => state.GET_DS_USER_INFO.data);
  const isLogInClicked = storage.local.get('IS_SIGN_IN_CLICKED');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [, getUserInfo] = useUserInfo();
  const leftNavHeaders = useLagoon('knowledge-base-left-nav-headers');
  const leftNavHeaderItems = useLagoon('knowledge-base-header-items');
  const featureToggles = useLagoon('feature-toggles');
  const communicationCorner = featureToggles('COMMUNICATION_CORNER')?.enabled;
  const priorAuthToggle = featureToggles('PRIOR_AUTH_TOGGLE')?.enabled;
  const TECHNICAL_DOCUMENTATION = 'Technical Documentation';
  const HEADER_LINK_CLICK = 'header link click';
  const hasInternalRole = checkInternalRole(dsUserInfo);
  const clientView = featureToggles('COMMUNICATION_CORNER_CLIENT_VIEW')
    ?.enabled;
  const providerDemoToggle = featureToggles('PROVIDER_DEMOGRAPHIC_TOGGLE')
    ?.enabled;
  const trackitToggle = featureToggles('TRACKIT_TOGGLE')?.enabled;

  const [showSubscribeAPIModal, setShowSubscribeAPIModal] = useState(false);

  let leftNavContent = leftNavHeaders();
  const leftHeaderItems = leftNavHeaderItems();

  leftNavContent = leftNavMap(leftNavContent, leftHeaderItems);

  const filteredLeftNavData = () => {
    const knoledgeBaseData = getLeftNavData(
      dsUserInfo,
      leftNavContent,
      leftHeaderItems
    );
    return knoledgeBaseData?.filter(el => el.accessible || el.accessibleToAll);
  };

  const routeToPage = ({
    tagging,
    interactionName,
    interactionContext,
    url,
  }) => {
    if (tagging) {
      const interactionValue = 'api technical documentation';
      event('INTERACTION_EVENT', {
        interactionName,
        interactionValue,
        interactionContext,
      });
    }
    router.push(url);
  };

  const fireHeaderEvent = (
    interactionValue,
    interactionName,
    interactionContext = 'sub header link click'
  ) => {
    event('INTERACTION_EVENT', {
      interactionName,
      interactionValue,
      interactionContext,
    });
  };

  const getNavItemsList = headerPaths => {
    const navItemsList = headerPaths?.map(path => {
      const title = param => {
        return (
          <CustomDiv>
            <DownloadDiv>{param}</DownloadDiv>
            <DownloadDiv>
              <IconMaterial icon="download" size="20px" />
            </DownloadDiv>
          </CustomDiv>
        );
      };
      const includesDownload = path.id.includes('download');
      return (
        <React.Fragment>
          <NavMenu.Item
            css={{
              'abyss-nav-menu-item-title': { fontSize: '15px' },
            }}
            key={path?.id}
            title={includesDownload ? title(path.name) : path.name}
            onClick={() => {
              if (includesDownload) {
                saveAs(path.path);
              } else {
                fireHeaderEvent(path.name, 'knowledge base');
                routeToPage({
                  url: path.path,
                });
              }
            }}
          />
        </React.Fragment>
      );
    });
    return navItemsList;
  };

  const getKnowledgeBaseHeader = () => {
    const pathsByTag = filteredLeftNavData();
    const knowledgeBaseMenuItemsList = pathsByTag?.map(el => {
      const { tag = '', paths = [] } = el || {};
      const headerPaths = paths;
      const navItemsList = getNavItemsList(headerPaths);
      return <NavMenu.Column title={tag}>{navItemsList}</NavMenu.Column>;
    });

    return <NavMenu.Columns>{knowledgeBaseMenuItemsList}</NavMenu.Columns>;
  };

  const signIn = () => {
    const interactionName = `welcome`;
    const interactionValue = 'welcome';
    const interactionContext = '';
    event('INTERACTION_EVENT', {
      interactionName,
      interactionValue,
      interactionContext,
    });

    storage.local.set('IS_SIGN_IN_CLICKED', true);
    if (storage.local.get('IS_SIGNED_OUT')) {
      storage.local.remove('IS_SIGNED_OUT');
    }

    if (process.env.NODE_ENV === 'development') {
      router.push('/welcome');
    } else {
      window.location.href = `${config('IDENTITY_API_URL')}/login`;
    }
  };

  const signOut = () => {
    storage.local.set('IS_SIGNED_OUT', true);
    runResetUserInfo();
    setshowLogoutSuccessModal(true);
    window.location.href = `${config('IDENTITY_API_URL')}/logout`;
    const modalName = 'sign out';
    event('MODAL_VIEW', {
      event,
      modalName,
    });
    event('MODAL_CLOSE', {
      event,
      modalName,
    });
  };

  const topMenuItemsSignIn = () => {
    setTopMenuItems([
      {
        title: (
          <Layout.Group space={0}>
            Sign in
            <Icon>
              <IconMaterial icon="person" size="$sm" />
            </Icon>
          </Layout.Group>
        ),
        content: (
          <NavMenu.Columns>
            <NavMenu.Column>
              <NavMenu.Item
                title="Sign into UnitedHealthcare API Marketplace"
                onClick={signIn}
                id="sign-in-button"
              />
              <NavMenu.Item
                title="Create a One Healthcare ID to register"
                onClick={signIn}
                description="New users that need access to UnitedHealthcare API Marketplace are required to create a OneHealthcare ID"
                id="one-healthcare-Id"
              />
            </NavMenu.Column>
          </NavMenu.Columns>
        ),
      },
    ]);
  };

  const orgManagementMenuItem = () => {
    const menuItems = [];
    if (!hasInternalRole) {
      menuItems.push(
        <NavMenu.Item
          title="Credentials"
          description="Manage and generate client ID, secret, and OAuth token"
          onClick={() => {
            fireHeaderEvent('Credentials', 'header', HEADER_LINK_CLICK);
            routeToPage({
              url: '/welcome/credentials',
            });
          }}
        />
      );
    }

    if (communicationCorner && hasInternalRole) {
      menuItems.push(
        <NavMenu.Item
          title="Create Communication"
          description="Create, save and send a communication"
          onClick={() => {
            fireHeaderEvent(
              'Create Communication',
              'header',
              HEADER_LINK_CLICK
            );
            routeToPage({
              url: '/welcome/create-communication',
            });
          }}
        />
      );
    }

    const { isAdmin } = dsUserInfo || {};
    const enableTinManagement = config('TIN_MANAGEMENT_CONFIG') && isAdmin;

    if (enableTinManagement) {
      menuItems.push(
        <React.Fragment>
          <TinManagementHeader>
            TIN Management
            <TinManagementDescription>
              Upload and modify your tins below
            </TinManagementDescription>
          </TinManagementHeader>

          <NavMenu.Item
            title="Review and Remove TINs"
            onClick={() => {
              fireHeaderEvent(
                'Review and Remove TINs',
                'header',
                HEADER_LINK_CLICK
              );
              routeToPage({
                url: '/welcome/review-tins',
              });
            }}
          />
          <NavMenu.Item
            title="Add New TINs"
            onClick={() => {
              fireHeaderEvent('Add New TINs', 'header', HEADER_LINK_CLICK);
              routeToPage({
                url: '/welcome/add-tins',
              });
            }}
          />
        </React.Fragment>
      );
    }

    return menuItems;
  };

  const topMenuItemsSignedIn = () => {
    const menuItems = [];
    if (
      (dsUserInfo?.status && dsUserInfo?.status === 'ACTIVE') ||
      hasInternalRole
    ) {
      const navItems = orgManagementMenuItem();
      menuItems.push({
        title: 'Organization Management',
        content: (
          <NavMenu.Columns>
            <NavMenu.Column>{navItems}</NavMenu.Column>
          </NavMenu.Columns>
        ),
      });
    }
    menuItems.push({
      title: (
        <Layout.Group space={0}>
          Sign Out&nbsp;
          <IconMaterial icon="logout" size="$sm" variant="outlined" />
        </Layout.Group>
      ),
      onClick: () => signOut(),
    });
    setTopMenuItems(menuItems);
  };

  useEffect(() => {
    if (userInfo.uuid === '') {
      topMenuItemsSignIn();
    } else {
      topMenuItemsSignedIn();
    }
  }, []);

  useEffect(() => {
    if (userInfo?.firstName) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [userInfo]);

  useEffect(() => {
    const handleInvalidToken = e => {
      if (e.key === 'IS_SIGNED_OUT' && storage.local.get('IS_SIGNED_OUT')) {
        setIsLoggedIn(false);
        signOut();
      }
    };
    window.addEventListener('storage', handleInvalidToken);
    return function cleanup() {
      window.removeEventListener('storage', handleInvalidToken);
    };
  }, [userInfoReset]);

  useEffect(() => {
    if (
      document.referrer.includes('identity') &&
      !storage.local.get('IS_SIGNED_OUT')
    ) {
      getUserInfo();
      if (isLogInClicked) {
        router.push('/welcome');
      }
    }
  }, [document.referrer]);

  useEffect(() => {
    if (userInfo.uuid === '') {
      topMenuItemsSignIn();
    } else {
      topMenuItemsSignedIn();
    }
  }, [userInfo, dsUserInfo]);

  const TechnicalDocumentationTitle = (
    <Layout.Group space={0}>
      <StyledFlex>
        <TechnicalDocHeader>{TECHNICAL_DOCUMENTATION}</TechnicalDocHeader>
        <APISandboxSubheader>Access API Sandbox</APISandboxSubheader>
      </StyledFlex>
    </Layout.Group>
  );

  const getDropdownMenuContent = (menu, menuTitle, eventTitle, type) => {
    return (
      <NavMenu.Columns>
        <NavMenu.Column title={menuTitle}>
          {menu &&
            menu.leftSubMenu &&
            menu.leftSubMenu.map(item => {
              return (
                <NavMenu.Item
                  title={item.title}
                  onClick={() => {
                    fireHeaderEvent(item.title, eventTitle);
                    routeToPage({
                      url: item.link,
                    });
                  }}
                />
              );
            })}
        </NavMenu.Column>
        <NavMenu.Column>
          {type !== 'allAPis' ? <VisiblyHiddenDiv /> : null}
          {menu &&
            menu.rightSubMenu &&
            menu.rightSubMenu.map(item => {
              return (
                <NavMenu.Item
                  title={item.title}
                  onClick={() => {
                    fireHeaderEvent(item.title, eventTitle);
                    routeToPage(item.routeParams);
                  }}
                />
              );
            })}
        </NavMenu.Column>
      </NavMenu.Columns>
    );
  };

  const prepareSubmenuByIndex = menu => {
    const leftSubMenu = [];
    const rightSubMenu = [];
    menu.map((item, index) => {
      if (index % 2 === 0) {
        return leftSubMenu.push(item);
      }
      return rightSubMenu.push(item);
    });
    return { leftSubMenu, rightSubMenu };
  };

  const getDropdownMenu = type => {
    let menuData =
      type === 'allAPis'
        ? dropdownMenu.allAPis
        : dropdownMenu.technicalDocumentation;

    menuData.sort((a, b) =>
      a.title.replace(/\s+/g, '').localeCompare(b.title.replace(/\s+/g, ''))
    );
    if (!providerDemoToggle) {
      menuData = menuData.filter(item => item.title !== 'Provider Demographic');
    }
    if (!priorAuthToggle) {
      menuData = menuData.filter(item => item.title !== 'Prior Authorizations');
    }
    if (!trackitToggle) {
      menuData = menuData.filter(item => item.title !== 'TrackIt');
    }
    const menu = prepareSubmenuByIndex(menuData);
    const title = type === 'allAPis' ? 'All APIs' : TechnicalDocumentationTitle;
    const eventTitle =
      type === 'allAPis' ? 'all apis' : TECHNICAL_DOCUMENTATION;
    return getDropdownMenuContent(menu, title, eventTitle, type);
  };

  const bottomMenuHeaderItems = [
    {
      title: 'Home',
      href: '#',
      header: 'notSignedIn',
      onClick: () => fireHeaderEvent('Home', 'header', HEADER_LINK_CLICK),
    },
    {
      title: 'Getting Started',
      href: '#/getting-started',
      header: 'notSignedIn',
      onClick: () =>
        fireHeaderEvent('Getting Started', 'header', HEADER_LINK_CLICK),
    },
    {
      title: 'Dashboard',
      href: '#/welcome',
      header: 'signedIn',
      onClick: () => fireHeaderEvent('Dashboard', 'header', HEADER_LINK_CLICK),
    },
    {
      title: 'All APIs',
      header: 'both',
      content: getDropdownMenu('allAPis'),
    },
    {
      title: 'Knowledge Base',
      header: 'signedIn',
      content: getKnowledgeBaseHeader(),
    },
    {
      title: TECHNICAL_DOCUMENTATION,
      header: 'both',
      content: getDropdownMenu('technical_documentation'),
    },
    {
      title: 'Resources',
      header: 'signedIn',
      content: (
        <NavMenu.Columns>
          <NavMenu.Column title="Getting Started">
            <NavMenu.Item
              title="Overview"
              onClick={() => {
                fireHeaderEvent('Overview', 'Resources');
                routeToPage({
                  url: '/getting-started',
                });
              }}
            />
            <If
              condition={
                dsUserInfo?.status &&
                dsUserInfo?.status === 'ACTIVE' &&
                !hasInternalRole
              }
            >
              <NavMenu.Item
                title="Credentials"
                onClick={() => {
                  fireHeaderEvent('Credentials', 'Resources');
                  routeToPage({
                    url: '/welcome/credentials',
                  });
                }}
              />
            </If>
          </NavMenu.Column>
          <NavMenu.Column title="Additional Resources">
            <NavMenu.Item
              title="Release Notes"
              onClick={() => {
                fireHeaderEvent('Release Notes', 'Resources');
                routeToPage({
                  url: '/welcome/release-notes/apim-latest-notes',
                });
              }}
            />
          </NavMenu.Column>
        </NavMenu.Columns>
      ),
    },
  ];

  const allCommunication = {
    title: 'Communications Corner',
    header: 'signedIn',
    content: (
      <NavMenu.Columns>
        <NavMenu.Column title="Communications Corner">
          <NavMenu.Item
            title="Connect with a Consultant"
            onClick={() => {
              fireHeaderEvent(
                'Connect with a Consultant',
                'Communications Corner'
              );
              setShowSubscribeAPIModal(true);
            }}
          />
        </NavMenu.Column>
        <If condition={dsUserInfo?.status && dsUserInfo?.status === 'ACTIVE'}>
          <NavMenu.Column>
            <NavMenu.Item
              title="All Communications"
              onClick={() => {
                fireHeaderEvent('All Communications', 'Communications Corner');
                routeToPage({
                  url: '/welcome/all-communications',
                });
              }}
            />
          </NavMenu.Column>
        </If>
      </NavMenu.Columns>
    ),
  };

  const bottomMenuItemsList =
    communicationCorner && clientView
      ? [...bottomMenuHeaderItems, allCommunication]
      : bottomMenuHeaderItems;

  const headerMenuItems = isUserAuthorized =>
    bottomMenuItemsList?.filter(
      el => el.header === isUserAuthorized || el.header === 'both'
    );

  const unAuthorizedUserHeaderManu = headerMenuItems('notSignedIn');
  const autorizedUserHeaderMenu = headerMenuItems('signedIn');

  const bottomMenuItems = isLoggedIn
    ? autorizedUserHeaderMenu
    : unAuthorizedUserHeaderManu;

  const closeAllModals = () => {
    setshowLogoutSuccessModal(false);
  };

  return (
    <Fragment>
      <SubscribeToAPIModal
        showModal={showSubscribeAPIModal}
        modalOnClose={setShowSubscribeAPIModal}
      />
      <PageHeader
        logoTitle="API Marketplace"
        logoRedirect="#"
        bottomMenuItems={bottomMenuItems}
        topMenuItems={topMenuItems}
        id="homeButton"
        css={{
          'abyss-page-header-logo-title': {
            color: '#002677',
            fontSize: '18px',
          },
          'abyss-page-header-toolbars': {
            minWidth: '500px',
          },
          'abyss-nav-menu-link': {
            fontSize: '15px',
            fontWeight: 600,
          },
          'abyss-nav-menu-menu-trigger': {
            fontSize: '15px',
            fontWeight: 600,
          },
          'abyss-nav-menu-item-title': {
            fontSize: '15px',
          },

          'abyss-nav-menu-column-title': {
            fontSize: '16px',
          },
        }}
      >
        <If condition={dsUserInfo?.status && dsUserInfo?.status === 'ACTIVE'}>
          <HeaderChildItemsDisplay>
            <Label color="$gray8">My Organization:&nbsp;</Label>
            <Badge variant="info">{dsUserInfo?.orgName}</Badge>
          </HeaderChildItemsDisplay>
        </If>
      </PageHeader>
      {showLogoutSuccessModal && (
        <LogoutSuccessModal closeAllModals={closeAllModals} />
      )}
    </Fragment>
  );
};
