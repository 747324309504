import { constants } from '../../../common/Utils/Constants';

export const relatedAPIsMock = [
  {
    title: 'Claims',
    linkLabel: constants.VIEW_API_DETAILS,
    link: '/all-apis/all-claims-api',
  },
  {
    title: 'Referrals',
    linkLabel: constants.VIEW_API_DETAILS,
    link: '/all-apis/referral-graphQL-api',
  },
  {
    title: 'Prior Authorizations',
    linkLabel: constants.VIEW_API_DETAILS,
    link: '/all-apis/prior-auth-api',
  },
];
