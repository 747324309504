export const dropdownMenu = {
  allAPis: [
    {
      routeParams: {
        url: '/all-apis/all-claims-api',
      },
      title: 'Claims',
      link: '/all-apis/all-claims-api',
    },
    {
      routeParams: {
        url: '/all-apis/claim-status-X12-api',
      },
      title: 'Claim Status (276/277) Extended X12',
      link: '/all-apis/claim-status-X12-api',
    },
    {
      routeParams: {
        url: '/all-apis/eligibility-api',
      },
      title: 'Eligibility',
      link: '/all-apis/eligibility-api',
    },
    {
      routeParams: {
        url: '/all-apis/referral-graphQL-api',
      },
      title: 'Referrals',
      link: '/all-apis/referral-graphQL-api',
    },
    {
      routeParams: {
        url: '/all-apis/eligibility-lightweight-api',
      },
      title: 'Eligibility (Lightweight)',
      link: '/all-apis/eligibility-lightweight-api',
    },
    {
      routeParams: {
        url: '/all-apis/eligibility-and-benefits-extended-x12-api',
      },
      title: 'Eligibility and Benefits (270/271) Extended X12',
      link: '/all-apis/eligibility-and-benefits-extended-x12-api',
    },
    {
      routeParams: {
        url: '/all-apis/provider-demographic-api',
      },
      title: 'Provider Demographic',
      link: '/all-apis/provider-demographic-api',
    },
    {
      routeParams: {
        url: '/all-apis/prior-auth-api',
      },
      title: 'Prior Authorizations',
      link: '/all-apis/prior-auth-api',
    },
    {
      routeParams: {
        url: '/all-apis/trackit-api',
      },
      title: 'TrackIt',
      link: '/all-apis/trackit-api',
    },
  ],
  technicalDocumentation: [
    {
      routeParams: {
        tagging: true,
        interactionName: 'all claims',
        interactionContext: 'all claims',
        url: '/documentation/claims',
      },
      title: 'Claims',
      link: '/documentation/claims',
    },
    {
      routeParams: {
        tagging: true,
        interactionName: 'eligibility',
        interactionContext: 'eligibility',
        url: '/documentation/eligibility',
      },
      title: 'Eligibility',
      link: '/documentation/eligibility',
    },
    {
      routeParams: {
        tagging: true,
        interactionName: 'referralsgraphql api',
        interactionContext: 'referralsgraphql api',
        url: '/documentation/referrals',
      },
      title: 'Referrals',
      link: '/documentation/referrals',
    },
    {
      routeParams: {
        tagging: true,
        interactionName: 'eligibility light weight services api',
        interactionContext: 'eligibility',
        url: '/documentation/eligibilitylw',
      },
      title: 'Eligibility (Lightweight)',
      link: '/documentation/eligibilitylw',
    },
    {
      routeParams: {
        tagging: true,
        interactionName: 'provider demographic api',
        interactionContext: 'provider demographic api',
        url: '/documentation/demographic',
      },
      title: 'Provider Demographic',
      link: '/documentation/demographic',
    },
    {
      routeParams: {
        tagging: true,
        interactionName: 'prior auth api',
        interactionContext: 'prior auth api',
        url: '/documentation/priorauth',
      },
      title: 'Prior Authorizations',
      link: '/documentation/priorauth',
    },
    {
      routeParams: {
        tagging: true,
        interactionName: 'trackIt api',
        interactionContext: 'trackIt api api',
        url: '/documentation/trackit',
      },
      title: 'TrackIt',
      link: '/documentation/trackit',
    },
  ],
};
