import React from 'react';
import GettingStartedGraphic from 'src/assets/GettingStarted-Graphic.png';
import { styled } from '@abyss/web/tools/styled';
import { useLagoon } from '@abyss/web/hooks/useLagoon';
import styles from './APIInfoSection.css';

const Wrapper = styled('div', {
  minHeight: '814px',
  background: 'rgb(255, 255, 255)',
  border: '1px solid rgb(219, 219, 219)',
  borderRadius: '0px',
  display: 'flex',
  flexDirection: 'column',
});

const Container1 = styled('div', {
  marginLeft: 'auto',
  display: 'flex',
  '@screen >= $xs': {
    flexDirection: 'column',
    height: 'auto',
    paddingTop: '46px',
    paddingBottom: '46px',
  },
  '@screen >= $xl': {
    flexDirection: 'row',
    height: '424px',
    paddingTop: '0px',
  },
});

const Container1Section1 = styled('div', {
  display: 'flex',
  flexBasis: '50%',
  height: '100%',
  flexDirection: 'column',
  justifyContent: 'center',
  marginLeft: 'auto',
  '@screen >= $xs': {
    padding: '0 16px',
  },
  '@screen >= $xl': {
    padding: '0 36px 0 49px',
  },
});

const Container1Title = styled('h1', {
  color: 'rgb(0, 38, 119)',
  fontSize: '28px ! important',
  fontWeight: '700',
  lineHeight: '65px',
  '@screen >= $xs': {
    lineHeight: '38px',
    marginBottom: '19px',
  },
  '@screen >= $xl': {
    marginTop: '50px',
  },
});

const Container1Description = styled('p', {
  minHeight: '144px',
  color: 'rgb(51, 51, 51)',
  fontSize: '15px',
  fontWeight: '400',
  letterSpacing: '0px',
  lineHeight: '24px',
});

const Container1Section2 = styled('div', {
  flexBasis: '50%',
});

const Container1Img = styled('img', {
  width: '100%',
  '@screen >= $xl': {
    height: '378px',
  },
});

const ItemHeader = styled('h3', {
  minHeight: '32px',
  color: 'rgb(0, 38, 119)',
  fontSize: '28px ! important',
  fontWeight: '700',
  lineHeight: '32px',
  marginBottom: '30px',
});

const Container2 = styled('div', {
  display: 'flex',
  padding: '0 24px',
  marginTop: '48px',
  marginBottom: '48px',
  '@screen >= $xs': {
    marginTop: 0,
    flexDirection: 'column',
  },
  '@screen >= $xl': {
    flexDirection: 'row',
    marginTop: '92px',
    marginLeft: '20px',
  },
});

const Container2ListItems = styled('li', {
  listStyle: 'disc',
  marginLeft: '25px',
  color: 'rgb(51, 51, 51)',
  fontSize: '15px',
  fontWeight: '400',
});

export const APIInfoSection = () => {
  const featureToggles = useLagoon('feature-toggles');
  const providerDemoToggle = featureToggles('PROVIDER_DEMOGRAPHIC_TOGGLE')
    ?.enabled;
  const priorAuthToggle = featureToggles('PRIOR_AUTH_TOGGLE')?.enabled;
  const trackitToggle = featureToggles('TRACKIT_TOGGLE')?.enabled;
  const items2Points = [
    'Eligibility and benefits',
    'Referrals',
    'Claim status and payments',
    'Claim reconsiderations (with attachments)',
    'Claim appeals (with attachments)',
    'Documents',
  ];
  if (providerDemoToggle) {
    items2Points.push('Provider Demographic Update API');
  }
  if (priorAuthToggle) {
    items2Points.push('Prior Authorizations');
  }
  if (trackitToggle) {
    items2Points.push('TrackIt');
  }

  const items2 = [
    {
      title: 'APIs we offer',
      points: items2Points,
      class: 'Item4',
    },
  ];
  const items = [
    {
      title: 'Flexibility',
      points: [
        'Used by practices, facilities, health care systems, vendors, clearinghouses and revenue cycle management companies.',
        'Various levels of data are accessible for each API type. Choose those that fit the needs of your organization.',
        'APIs are available for many UnitedHealthcare payers and plans. Payer IDs are indicated in the API technical documentation.',
      ],
      class: 'Item1',
    },
    {
      title: 'Efficiency',
      points: [
        'Fill in gaps for information you may not be getting in your current data streams, such as electronic data interchange (EDI).',
        'Share and distribute data throughout your practice more easily and efficiently.',
        'Streamline daily workflows and reduce staff time spent on manually retrieving information from the UnitedHealthcare Provider Portal.',
      ],
      class: 'Item2',
    },
    {
      title: 'Security',
      points: [
        'To ensure a high data security standard for your stored and transmitted data. UnitedHealthcare applies extensive security measures in several areas.',
      ],
      class: 'Item3',
    },
  ];

  const createCon2 = displayItems => {
    const k = displayItems.map(el => {
      return (
        <div className={styles[el.class]}>
          <ItemHeader>{el.title}</ItemHeader>
          <ul>
            {el.points.map(m => (
              <div>
                <If condition={displayItems === items2}>
                  <Container2ListItems style={{ lineHeight: '24px' }}>
                    {m}
                  </Container2ListItems>
                </If>
                <If condition={displayItems === items}>
                  <Container2ListItems style={{ lineHeight: '22px' }}>
                    {m}
                  </Container2ListItems>
                </If>
              </div>
            ))}
          </ul>
        </div>
      );
    });

    return k;
  };

  return (
    <Wrapper>
      <Container1>
        <Container1Section1>
          <Container1Title>Why add API to your practice?</Container1Title>
          <Container1Description>
            API is designed to help your organization improve efficiency, reduce
            costs and increase cash flow. Phone calls and paper handling
            decrease, while contributing to a smoother workﬂow with fewer
            interruptions. Automatic data feeds are created to pull information
            on a timetable you set, and transfer the data to your practice
            management system, proprietary software, portal, spreadsheets or any
            application you prefer.
            <div style={{ marginTop: '30px' }}>{createCon2(items2)}</div>
          </Container1Description>
        </Container1Section1>
        <Container1Section2>
          <Container1Img src={GettingStartedGraphic} alt="Getting Started" />
        </Container1Section2>
      </Container1>
      <Container2 style={{ marginTop: '170px' }}>
        {createCon2(items)}
      </Container2>
    </Wrapper>
  );
};
