import React from 'react';
import PropTypes from 'prop-types';
import './RenderHtml.css';

export const RenderHtml = ({ children, ...props }) => {
  return !children ? null : (
    <div
      {...props}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: children,
      }}
    />
  );
};

RenderHtml.propTypes = {
  children: PropTypes.node,
};

RenderHtml.defaultProps = {
  children: null,
};
