import { constants } from '../../../common/Utils/Constants';

export const data = {
  'all-apis': 'All APIs',
  'all-claims-api': 'Claims API',
  'prior-auth-api': 'Prior Authorizations API',
  'under-construction': 'Under Construction',
  'provider-demographic-api': 'Provider Demographic API',
  'trackit-api': 'TrackIt API',
  'claim-status-X12-api': 'Claim Status (276/277) Extended X12 API ',
  'eligibility-lightweight-api': 'Eligibility (Light Weight) API',
  'referral-graphQL-api': 'Referrals API',
  'eligibility-api': 'Eligibility API',
  'eligibility-and-benefits-extended-x12-api':
    'Eligibility and Benefits (270/271) Extended X12 API',
  welcome: 'Account Dashboard',
  credentials: 'Credentials',
  'all-communications': 'All Communications',
  'communication-corner': 'Communications Corner',
  'create-communication': 'Create Communication',
  'knowledge-base': 'Knowledge Base',
  'claims-feedback': constants.API_FEEDBACK,
  'eligibility-feedback': constants.API_FEEDBACK,
  'eligibilitylw-feedback': constants.API_FEEDBACK,
  'referrals-feedback': constants.API_FEEDBACK,
  'priorauth-feedback': constants.API_FEEDBACK,
  'providerdemographic-feedback': constants.API_FEEDBACK,
  'trackit-feedback': constants.API_FEEDBACK,
  'release-notes': 'Release Notes',
  'review-tins': 'Review or Remove TINs',
  'add-tins': 'Add TINs',
  'submitted-tins': 'Review & Submit',
};
