export const apiItems = [
  {
    src: 'clipboard',
    label: 'Claims',
    description:
      'Get summary and detailed claim status and payments, coordination of benefits, submit reconsiderations and appeals with attachments, and retrieve documents.',
    id: 1,
    key: 'All-Claims-API',
    categoryId: 1,
    route: 'all-apis/all-claims-api',
    apiCategory: ['claims'],
  },
  {
    src: 'id_card',
    label: 'Eligibility',
    description:
      'Find quick access to primary care provider (PCP) details, plan coverage, benefit details and your members ID cards.',
    id: 6,
    categoryId: 2,
    route: '/all-apis/eligibility-api',
    apiCategory: ['eligibility'],
  },
  {
    src: 'id_card',
    label: 'Eligibility (Lightweight)',
    description:
      'Get to eligibility information such as including demographics, coverage attributes and basic member information.',
    id: 8,
    key: 'Eligibility-Lightweight-API',
    categoryId: 2,
    route: '/all-apis/eligibility-lightweight-api',
    apiCategory: ['eligibility', 'eligibilitylw'],
  },

  {
    src: 'chat',
    label: 'Referrals',
    description:
      'The Referrals API provides detailed information about previous referrals and referral submissions. ',
    id: 9,
    categoryId: 4,
    key: 'referrals-api',
    route: '/all-apis/referral-graphQL-api',
    apiCategory: ['referrals'],
  },
  {
    src: 'clipboard',
    label: 'Claim Status (276/277) Extended X12 API',
    description:
      'Inquire about the status of a claim or respond to a request inquiry.',
    id: 1,
    key: 'Claim Status (276/277) X12',
    categoryId: 5,
    route: 'all-apis/claim-status-X12-api',
    apiCategory: ['claims', 'extended x12'],
  },
  {
    src: 'id_card',
    label: 'Eligibility and Benefits (270/271) Extended X12 API',
    description:
      'Use the Eligibility and Benefit Inquiry (270) transaction to inquire about the health care eligibility and benefits associated with a subscriber or dependent.',
    id: 12,
    key: 'Eligibility and Benefits (270/271)',
    categoryId: 5,
    route: '/all-apis/eligibility-and-benefits-extended-x12-api',
    apiCategory: ['eligibility', 'extended x12'],
  },
  {
    src: 'mobile_clinic',
    label: 'Provider Demographic API',
    description:
      'Update provider demographics (add, terminate, change) directly from a practice management system or any application using HL7 FHIR format.',
    id: 13,
    key: 'Provider Demographic API',
    categoryId: 6,
    route: '/all-apis/provider-demographic-api',
    apiCategory: ['provider demographic'],
  },
  {
    src: `clipboard_checked`,
    label: 'Prior Authorizations',
    description: 'Search and review prior authorization status details. ',
    id: 11,
    key: 'PriorAuth-API',
    categoryId: 7,
    route: '/all-apis/prior-auth-api',
    apiCategory: ['prior authorizations'],
  },
  {
    src: `cursor`,
    label: 'TrackIt',
    description:
      'These APIs are designed to facilitate efficient tracking and management of action and notifications.',
    id: 11,
    key: 'TrackIt',
    categoryId: 8,
    route: '/all-apis/trackit-api',
    apiCategory: ['trackit'],
  },
];
