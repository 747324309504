import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { PageLayout } from 'src/common/PageLayout';
import { event } from '@abyss/web/tools/event';
import { Breadcrumbs } from 'src/app/App/Breadcrumbs/Breadcrumbs';
import { Testimonials } from 'src/common/Testimonials/Testimonials';
import { testimonialMock } from 'src/app/App/__mock_data__/testimonialMock';
import { relatedAPIsMock } from 'src/app/App/__mock_data__/trackit_relatedAPIs';
import { APISlider } from 'src/common/APISlider/APISlider';
import { StyledLink } from 'src/common/Link';
import { Button } from '@abyss/web/ui/Button';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { useRouter } from '@abyss/ui/router/hooks/useRouter';
import { styled } from '@abyss/web/tools/styled';
import { IconBrand } from '@abyss/web/ui/IconBrand';
import { SubscribeModal } from '../SubscribeModal';
import { SubscribeToAPIModal } from '../../SubscribeToAPIModal';
import { useDSUserInfo } from '../../../../../hooks/useDSUserInfo';
import { constants } from '../../../../../common/Utils/Constants';

const Wrapper = styled('div', {
  '@screen >= $xs': {
    padding: '16px',
  },

  '@screen >= $xl': {
    padding: '32px 0 0 0',
  },
});

const StyledFlex = styled('div', {
  display: 'flex',
  flexBasis: '75%',
});

const Container = styled('div', {
  flexBasis: '100%',
});

const PageHeadingContainer = styled('div', {
  display: 'flex',
  marginTop: '8px',
  marginBottom: '32px',
  flexBasis: '50%',
});

const Icon = styled('div', {
  height: '49.5px',
  width: '60px',

  '@screen >= $xs': {
    display: 'none',
  },

  '@screen >= $sm': {
    display: 'block',
  },
});

const PageHeading = styled('h1', {
  color: 'rgb(0, 38, 119)',
  fontSize: '36px',
  fontWeight: '600',
  minHeight: '40px',
  lineHeight: '40px',
});

const PageSubHeading = styled('h2', {
  color: 'rgb(0, 38, 119)',
  fontSize: '28px !important',
  fontWeight: '600',
  minHeight: '32px',
  lineHeight: '32px',
  marginBottom: '16px',
});

const PageSecondaySubHeading = styled('h4', {
  color: `${constants.FONT_COLOR_GREY} !important`,
  fontSize: '23px !important',
  fontWeight: 'bold',
  lineHeight: '24px',
  marginBottom: '16px',
  marginTop: '16px',
});

const PageLinkContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  margin: '0',
  marginTop: '32px',
  marginBottom: '0px',
});

const PageDesc = styled('div', {
  color: `${constants.FONT_COLOR_GREY} !important`,
  fontSize: '16px',
  fontWeight: 400,
  letterSpacing: '0px',
  lineHeight: '24px',
  marginBottom: '16px',
});

const PageDesc2 = styled('div', {
  color: `${constants.FONT_COLOR_GREY} !important`,
  fontSize: '16px',
  fontWeight: 400,
  letterSpacing: '0px',
  lineHeight: '24px',
  marginBottom: '48px',
});

const PageContent = styled('div', {
  color: `${constants.FONT_COLOR_GREY} !important`,
  fontSize: '16px',
  fontWeight: 400,
  minHeight: '275px',
  letterSpacing: '0px',
  lineHeight: '24px',
  marginBottom: '48px',
});

const UL = styled('ul', {
  margin: '20px 0px 32px 48px',
  listStyle: 'disc',
});

const LI = styled('li', {
  margin: '8px',
  fontWeight: 'normal',
});

const AnchorBox = styled('div', {
  height: '20px',
  display: 'flex',
  alignContent: 'center',
  marginBottom: '16px',
  marginTop: '8px',
  marginLeft: '8px',
});

const ButtonMargin = styled('div', {
  margin: '24px 0',

  '@screen >= $xs': {
    button: {
      width: '100%',
    },
  },

  '@screen >= $sm': {
    button: {
      width: '339px',
    },
  },

  '@screen >= $xl': {
    textAlign: 'right',
  },
});

const Flexed = styled('div', {
  '@screen >= $xs': {
    display: 'block',
  },

  '@screen >= $xl': {
    display: 'flex',
  },
});

const ContainerHalf = styled('div', {
  flexBasis: '50%',
});

const SecondHeading = styled('div', {
  color: `${constants.FONT_COLOR_GREY} !important`,
  fontSize: '23px !important',
  fontWeight: 'bold',
  lineHeight: '24px',
  marginBottom: '16px',
  marginTop: '16px',
});

const LinkMargin = styled('div', {
  marginRight: '12px',
  alignSelf: 'center',
});

export const TrackItAPI = () => {
  const dsUserInfo = useSelector(state => state.GET_DS_USER_INFO);
  const userInfo = useSelector(state => state.GET_USER_INFO.data);
  const [, getDSUserInfo] = useDSUserInfo();

  const [isSubscribeModalOpen, setIsSubscribeModalOpen] = useState(false);
  const [showSubscribeAPIModal, setShowSubscribeAPIModal] = useState(false);
  const router = useRouter();

  const interactionContextPageCTALinkClicks = 'page cta link clicks';
  const interactionName = 'provider demographic api';

  useEffect(() => {
    if (userInfo.uuid && !dsUserInfo.data.status) {
      getDSUserInfo({ userInfo });
    }
  }, [userInfo]);

  useEffect(() => {
    event('API_DETAILS_PROVIDERDEMOGRAPHIC_PAGE_LOAD', {
      dsUserInfo,
      userInfo,
    });
  }, []);

  const subscribeToAPI = () => {
    const interactionValue = constants.SUBSCRIBE_TO_API;
    event('INTERACTION_EVENT', {
      interactionName,
      interactionValue,
      interactionContext: interactionContextPageCTALinkClicks,
    });
  };

  const subscribeClicked = () => {
    const jobName = constants.SUB_TO_API_JOB_NAME;
    subscribeToAPI();
    if (userInfo.firstName === '' || userInfo.error) {
      setIsSubscribeModalOpen(true);
    } else {
      event('SUBSCRIBE_EVENT', {
        event,
        jobName,
      });
      setShowSubscribeAPIModal(true);
    }
  };

  const accessSandbox = () => {
    const interactionValue = 'access sandbox';
    event('INTERACTION_EVENT', {
      interactionName,
      interactionValue,
      interactionContext: interactionContextPageCTALinkClicks,
    });
    router.push('/documentation/trackit');
  };

  const apiDocumentation = () => {
    const interactionValue = 'api technical documentation';
    const interactionContext = 'page cta link clicks';
    event('INTERACTION_EVENT', {
      interactionName,
      interactionValue,
      interactionContext,
    });
    router.push('/documentation/trackit');
  };

  return (
    <PageLayout>
      <SubscribeModal
        setIsSubscribeModalOpen={setIsSubscribeModalOpen}
        isSubscribeModalOpen={isSubscribeModalOpen}
      />
      <SubscribeToAPIModal
        showModal={showSubscribeAPIModal}
        modalOnClose={setShowSubscribeAPIModal}
      />
      <Wrapper>
        <StyledFlex>
          <Container>
            <Breadcrumbs />
            <Flexed>
              <PageHeadingContainer>
                <Icon>
                  <IconBrand icon="cursor" size={48} />
                </Icon>
                <PageHeading>TrackIt API</PageHeading>
              </PageHeadingContainer>
              <ContainerHalf>
                <ButtonMargin>
                  <Button
                    variant="solid"
                    onClick={apiDocumentation}
                    css={{
                      backgroundColor: '#002677',
                      fontSize: '15px',
                      height: '42px',

                      '@screen >= $xs': {
                        width: '100%',
                      },

                      '@media (min-width: 576px)': {
                        width: '339px',
                      },
                    }}
                  >
                    API Technical Documentation
                  </Button>
                </ButtonMargin>
              </ContainerHalf>
            </Flexed>
            <PageSubHeading>Overview</PageSubHeading>
            <PageDesc>
              The TrackIt APIs retrieve information for the following
              timeframes: Documentation within 5 days, and Commercial, Medicare,
              and Medicaid Pending Claims within 60 days.
            </PageDesc>
            <PageLinkContainer>
              <AnchorBox>
                <LinkMargin>
                  <StyledLink
                    id="SubscribeToAPI"
                    onClick={subscribeClicked}
                    after={<IconMaterial icon="arrow_forward" size="20px" />}
                    fontWeight="$bold"
                  >
                    {constants.SUBSCRIBE_TO_API}
                  </StyledLink>
                </LinkMargin>
              </AnchorBox>
              <AnchorBox>
                <LinkMargin>
                  <StyledLink
                    onClick={accessSandbox}
                    after={<IconMaterial icon="arrow_forward" size="20px" />}
                    fontWeight="$bold"
                  >
                    Access Sandbox
                  </StyledLink>
                </LinkMargin>
              </AnchorBox>
            </PageLinkContainer>
            <PageSecondaySubHeading>About TrackIt API</PageSecondaySubHeading>
            <PageContent>
              <div>
                The TrackIt API provides a comprehensive suite of endpoints
                designed to facilitate efficient tracking and management of
                actions and notifications.
              </div>
              <div>Below are the key API types and their descriptions:</div>
              <UL>
                <LI>Action Summary</LI>
                <UL style={{ listStyle: 'circle' }}>
                  <LI>
                    This endpoint provides a summary of actions, including the
                    type of record, its status, and the count of each type. It
                    is useful for obtaining a quick overview of the current
                    state of various actions. The record types include
                    Reconsiderations, Pended Tickets, Medicare Pending,
                    Commercial Pending, Appeal Tickets, Prior Authorization
                    Appeals, Prior Authorization.
                  </LI>
                </UL>

                <LI>Notification Summary</LI>
                <UL style={{ listStyle: 'circle' }}>
                  <LI>
                    This endpoint returns the counts for different types of
                    notifications. It helps in understanding the volume and
                    distribution of notifications across different categories.
                    The record Types include Admission, Discharge, Emergency and
                    Referrals.
                  </LI>
                </UL>
                <LI>Action detail </LI>
                <UL style={{ listStyle: 'circle' }}>
                  <LI>
                    {' '}
                    This endpoint retrieves detailed information about specific
                    actions. It allows users to access the actual data
                    associated with each action, providing in-depth insights and
                    facilitating detailed analysis. The record types include
                    Reconsiderations, Pended Tickets, Medicare Pending,
                    Commercial Pending, Appeal Tickets, Prior Authorization
                    Appeals, Prior Authorization.
                  </LI>
                </UL>
                <LI>Notification Details </LI>
                <UL style={{ listStyle: 'circle' }}>
                  <LI>
                    {' '}
                    This endpoint provides detailed information about specific
                    notifications. Users can access the full details of each
                    notification, enabling thorough review and management. The
                    record Types include Admission, Discharge, Emergency and
                    Referrals.
                  </LI>
                </UL>
              </UL>
            </PageContent>
            <SecondHeading>Prerequisites</SecondHeading>
            <PageDesc2>
              The Consumer should have valid Client-ID/Secrets for the APIs to
              be fully functional.
            </PageDesc2>
            <SecondHeading>Security</SecondHeading>
            <PageDesc2>
              API's are secured using OAuth 2.0. All calls to APIs are encrypted
              over HTTPS. APIs support connections using TLS version 1.2 or
              higher. All modern languages and frameworks support TLS 1.2
              (although specific older ones do not).
            </PageDesc2>
          </Container>
        </StyledFlex>
        <Testimonials initialData={testimonialMock} />
        <APISlider initialData={relatedAPIsMock} />
      </Wrapper>
    </PageLayout>
  );
};
