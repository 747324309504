import React from 'react';
import { styled } from '@abyss/web/tools/styled';
import { event } from '@abyss/web/tools/event';
import { useLagoon } from '@abyss/web/hooks/useLagoon';
import { APIsCategoryCard } from '../../../../../common/APICard';

const Wrapper = styled('div', {
  margin: '64px 0 0 0',
});

const Heading = styled('h3', {
  minHeight: '32px',
  color: 'rgb(0, 38, 119)',
  fontSize: '28px ! important',
  fontWeight: 600,
  lineHeight: '32px',
  marginBottom: '45px',
});

const CategoryBox = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gap: '15px',
});

export const APIsCategory = () => {
  const allCards = useLagoon('api-categories');
  const cards = allCards()?.filter(card => card.active);

  return (
    <Wrapper>
      <Heading id="Category">APIs By Category</Heading>
      <CategoryBox>
        {cards?.map((el, i) => {
          return (
            <APIsCategoryCard
              src={el.icon}
              title={el.title}
              description={el.description}
              link={el.link}
              linkLabel={el.linkLabel}
              index={i}
              badge={el.displayBadge}
              badgeMessage={el.badgeMessage}
              badgeLink={el.badgeLink}
              badgeVariant={el.badgeVariant}
              badgeIcon={el.badgeIcon}
              onClick={() => {
                const interactionName = el.title;
                const interactionValue = el.linkLabel;
                const interactionContext = 'tile cta clicks';
                event('INTERACTION_EVENT', {
                  interactionName,
                  interactionValue,
                  interactionContext,
                });
              }}
            />
          );
        })}
      </CategoryBox>
    </Wrapper>
  );
};
